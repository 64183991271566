<template>
  <div class="wlnh">
    <div class="left-box">
      <ul>
        <li class="items1">
          <div class="items1_title">进水总量</div>
          <div
            class="items1_value"
            @click="toCompon(2, 'AN213', 'DB1V', 'AN213_JLT', '进水总量')"
            @dblclick="
              Cclick(infoList.DB1V.AN213, 'AN213', 'DB1V', 'AN213_JLT')
            "
          >
            {{ infoList.DB1V.AN213 }} m³
          </div>
        </li>
        <li>
          <div class="line-arrow">
            <div class="line"></div>
          </div>
        </li>
        <li class="items1 jiange">
          <div class="items1_title">蒸汽总量</div>
          <div
            class="items1_value"
            @click="toCompon(2, 'AN141_JLT', 'DB1V', 'AN141_JLT', '蒸汽总量')"
            @dblclick="
              Cclick(infoList.DB1V.AN141, 'AN141', 'DB1V', 'AN141_JLT')
            "
          >
            {{ infoList.DB1V.AN141 }} L
          </div>
        </li>
        <li>
          <div class="line-arrow">
            <div class="line"></div>
          </div>
        </li>
      </ul>
    </div>
    <div class="center-box">
      <div class="center-box-top">
        <div class="box1">
          <div class="line1">换热装置</div>
          <div class="line2">
            <div class="line2-item"></div>
            <div class="line2-item lable_color">累积值</div>
            <div class="line2-item lable_color">实时值</div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">用电量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN207', 'DB1V', 'AN207_JLT', '用电量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN207, 'AN207', 'DB1V', 'AN207_JLT')">
              {{ infoList.DB1V.AN207 }}Kwh
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN215', 'DB1V', 'AN215_JLT', '用电量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN215, 'AN215', 'DB1V', 'AN215_JLT')">
              {{ infoList.DB1V.AN215 }}Kwh
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
        </div>
        <div class="box1">
          <div class="line1">公用工程</div>
          <div class="line2">
            <div class="line2-item"></div>
            <div class="line2-item lable_color">累积值</div>
            <div class="line2-item lable_color">实时值</div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">用电量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN208', 'DB1V', 'AN208_JLT', '用电量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN208, 'AN208', 'DB1V', 'AN208_JLT')">
              {{ infoList.DB1V.AN208 }}Kwh
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN216', 'DB1V', 'AN216_JLT', '用电量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN216, 'AN216', 'DB1V', 'AN216_JLT')">
              {{ infoList.DB1V.AN216 }}Kwh
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
        </div>
        <div class="box1">
          <div class="line1">精馏装置</div>
          <div class="line2">
            <div class="line2-item"></div>
            <div class="line2-item lable_color">累积值</div>
            <div class="line2-item lable_color">实时值</div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">用电量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN209', 'DB1V', 'AN209_JLT', '用电量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN209, 'AN209', 'DB1V', 'AN209_JLT')">
              {{ infoList.DB1V.AN209 }}Kwh
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN217', 'DB1V', 'AN217_JLT', '用电量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN217, 'AN217', 'DB1V', 'AN217_JLT')">
              {{ infoList.DB1V.AN217 }}Kwh
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">进料量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN132', 'DB1V', 'AN132_JLT', '进料量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN132, 'AN132', 'DB1V', 'AN132_JLT')">
              {{ infoList.DB1V.AN132 }}L</div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'FI101', 'DB1S', 'FI101', '进料量实时值')"
              @dblclick="
                Cclick(infoList.DB1S.FI101, 'FI101', 'DB1S', 'FI101')">
              {{ infoList.DB1S.FI101 }}L/h
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">回流量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN133', 'DB1V', 'AN133_JLT', '回流量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN133, 'AN133', 'DB1V', 'AN133_JLT')">
              {{ infoList.DB1V.AN133 }}L
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN180', 'DB1V', 'AN180_JLT', '回流量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN180, 'AN180', 'DB1V', 'AN180_JLT')">
              {{ infoList.DB1V.AN180 }}L/h
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">采出量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN134', 'DB1V', 'AN134_JLT', '采出量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN134, 'AN134', 'DB1V', 'AN134_JLT')">
              {{ infoList.DB1V.AN134 }}L
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN181', 'DB1V', 'AN181_JLT', '采出量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN181, 'AN181', 'DB1V', 'AN181_JLT')">
              {{ infoList.DB1V.AN181 }}L/h
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">残液量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN135', 'DB1V', 'AN135_JLT', '残液量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN135, 'AN135', 'DB1V', 'AN135_JLT')">
              {{ infoList.DB1V.AN135 }}L
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'FI104', 'DB1S', 'FI104', '残液量实时值')"
              @dblclick="
                Cclick(infoList.DB1S.FI104, 'FI104', 'DB1S', 'FI104')">
              {{ infoList.DB1S.FI104 }}L/h
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">冷凝水</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN136', 'DB1V', 'AN136_JLT', '冷凝水累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN136, 'AN136', 'DB1V', 'AN136_JLT')">
              {{ infoList.DB1V.AN136 }}L
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'FI104', 'DB1S', 'FI104', '冷凝水实时值')"
              @dblclick="
                Cclick(infoList.DB1S.FI104, 'FI104', 'DB1S', 'FI104')">
              {{ infoList.DB1S.FI104 }}L/h
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
        </div>
      </div>
      <div class="center-box-top">
        <div class="box1">
          <div class="line1">反应釜装置</div>
          <div class="line2">
            <div class="line2-item"></div>
            <div class="line2-item lable_color">累积值</div>
            <div class="line2-item lable_color">实时值</div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">用电量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN210', 'DB1V', 'AN210_JLT', '反应釜装置用电量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN210, 'AN210', 'DB1V', 'AN210_JLT')">
              {{ infoList.DB1V.AN210 }}Kwh
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN218', 'DB1V', 'AN218_JLT', '反应釜装置用电量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN218, 'AN218', 'DB1V', 'AN218_JLT')">
              {{ infoList.DB1V.AN218 }}Kw
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
        </div>
        <div class="box1">
          <div class="line1">四容水槽装置</div>
          <div class="line2">
            <div class="line2-item"></div>
            <div class="line2-item lable_color">累积值</div>
            <div class="line2-item lable_color">实时值</div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">用电量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN211', 'DB1V', 'AN211_JLT', '四容水槽装置用电量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN211, 'AN211', 'DB1V', 'AN211_JLT')">
              {{ infoList.DB1V.AN211 }}Kwh
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN219', 'DB1V', 'AN219_JLT', '四容水槽装置用电量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN219, 'AN219', 'DB1V', 'AN219_JLT')">
              {{ infoList.DB1V.AN219 }}Kw
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
        </div>
        <div class="box1">
          <div class="line1">干燥装置</div>
          <div class="line2">
            <div class="line2-item"></div>
            <div class="line2-item lable_color">累积值</div>
            <div class="line2-item lable_color">实时值</div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">用电量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN212', 'DB1V', 'AN212_JLT', '干燥装置用电量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN212, 'AN212', 'DB1V', 'AN212_JLT')">
              {{ infoList.DB1V.AN212 }}Kwh
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN220', 'DB1V', 'AN220_JLT', '干燥装置用电量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN220, 'AN220', 'DB1V', 'AN220_JLT')">
              {{ infoList.DB1V.AN220 }}Kw
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <ul>
        <li class="items1">
          <div class="items1_title">废气总量</div>
          <div class="items1_value"
          @click="toCompon(2, 'FI203', 'DB1S', 'FI203', '废气总量')"
              @dblclick="
                Cclick(infoList.DB1S.FI203, 'FI203', 'DB1S', 'FI203')">
              {{ infoList.DB1S.FI203 }}Nm3/h
         </div>
        </li>
        <li>
          <div class="line-arrow">
            <div class="line"></div>
          </div>
        </li>
        <li class="items1 jiange">
          <div class="items1_title">回水总量</div>
          <div class="items1_value"
          @click="toCompon(2, 'AN214', 'DB1V', 'AN214_JLT', '回水总量')"
              @dblclick="
                Cclick(infoList.DB1V.AN214, 'AN214', 'DB1V', 'AN214_JLT')">
              {{ infoList.DB1V.AN214 }}m³
         </div>
        </li>
        <li>
          <div class="line-arrow">
            <div class="line"></div>
          </div>
        </li>
      </ul>
    </div>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
  </div>
</template>
<script>
import index from "./_index/index.js";
import Manual from "@/components/Manual.vue"; //手操器组件
//import Evaluate from "@/components/Evaluate.vue"; //运行评价组件
//import Security from "@/components/Security.vue"; //安全设置组件
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  name: "d6pub",
  components: {
    Manual,
    Firstcontrol,
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
    };
  },
  mixins: [index],
  created() {
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },

      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
  },

};
</script>
<style scoped lang="scss">
.wlnh {
  display: flex;
  padding-top: 2vh;
  box-sizing: border-box;
  .left-box {
    width: 15vw;
    height: 80vh;
    display: flex;
    align-items: center;
    margin-left: 5vw;
    margin-right: 1vw;
  }
  .right-box {
    width: 15vw;
    height: 80vh;
    display: flex;
    align-items: center;
    // margin-left: 5vw;
    margin-right: 1vw;
  }
  .center-box {
    width: 60vw;
    height: 80vh;
    border: 1px solid #67989d;
    border-bottom: none;

    .center-box-top {
      display: flex;
    }
    .box1 {
      width: 20vw;
      // border-right: 1px solid #67989d;
      // border-bottom: 1px solid #67989d;
      height: 40vh;
      .line1 {
        width: 19.95vw;
        height: 5vh;
        color: #ff09db;
        border-bottom: 1px solid #67989d;
        border-right: 1px solid #67989d;
        line-height: 5vh;
        text-align: center;
      }
      .line2 {
        display: flex;
        color: #01fff6;
        .line2-item {
          width: 6.7vw;
          height: 4.3vh;
          line-height: 4.3vh;
          border-bottom: 1px solid #67989d;
          border-right: 1px solid #67989d;
          text-align: center;
          cursor: pointer;
        }
      }
      .value_color {
        color: #d9feff;
      }
      .lable_color {
        color: #458ca4;
      }
    }
  }
  .items1 {
    display: flex;
    width: 15vw;
    height: 4vh;
    line-height: 4vh;
    color: #01fff6;
    // border: 1px solid #fff;
    text-align: center;
  }
  .jiange {
    margin-top: 4vh;
  }
  .items1_title {
    color: #ff09db;
    width: 5vw;
    height: 4vh;
  }
  .items1_value {
    width: 5vw;
    height: 3.5vh;
    color: #01fff6;
  }
  .line-arrow {
    display: flex;
    align-items: center;
  }
  .line {
    flex-grow: 1;
    height: 1px;
    background-color: rgb(248, 242, 242);
    position: relative;
  }
  .line::after {
    content: "";
    position: absolute;
    right: -10px; /* Adjust as needed */
    top: 20%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid rgb(248, 245, 245);
  }
}
</style>
